

    function getActiveButtonId() {
      const buttons = document.querySelectorAll('.budget-button');
      let activeButtonId = '';
      buttons.forEach((button) => {
        if (button.classList.contains('active')) {
          activeButtonId = button.id;
        }
      });
      return activeButtonId;
    }
    window.onload = function () {
      document.getElementById('contact-form').addEventListener('submit', function (event) {
        event.preventDefault();
        let formData = new FormData(event.target);
        let formParams = Object.fromEntries(formData.entries());
        formParams.selectedButton = getActiveButtonId();
        emailjs.send('default_service', 'template_2a9qiqy', formParams)
          .then(() => {
            console.log('SUCCESS!');
            document.getElementById('contact-form').reset();
            document.getElementById('send-button').innerHTML = 'MESSAGE HAS BEEN SENT';
            document.getElementById('send-button').classList.add('success');
            document.getElementById('rocketsvg').classList.add('success');
            let translateY = - (document.getElementById('send-button').getBoundingClientRect().width - document.getElementById('rocketsvg').getBoundingClientRect().width) / document.getElementById('rocketsvg').getBoundingClientRect().width * 100;
            document.getElementById('rocketsvg').style.transform = `rotate(90deg) translateY(${translateY}%)`;
            setTimeout(() => {
              document.getElementById('rocketsvg').classList.add('finished');
            }, 2000);
          }, (error) => {
            console.log('FAILED...', error);
            document.getElementById('contact-form').reset();
            document.getElementById('send-button').innerHTML = 'MESSAGE COULD NOT BE SENT';
            setTimeout(() => {
              document.getElementById('rocketsvg').classList.add('finished');
            }, 2000);
          });
      });
      console.log("All JS files have been completely loaded.");
      setTimeout(function () {

      }, 500);
    };



  